<script>
    import Teams from '@/services/Teams'
    import Swal from "sweetalert2";

    export default {
        props: {
        },
        data() {
            return {
                showModal: false,
                tryingToEdit: false,
                round_id: null,
                match_id: null,
                competition_id: process.env.VUE_APP_COMPETITION_ID, 
                matchesPerRound: [],
                competitionRounds: [],
                tryingToSubmit: false,
            };
        },
        // mounted() {
        //     this.getCompetitionRounds();
        // },
        methods: {
            refreshTableFeatureMatches() { 
                this.$emit('tableRefresh') //event from parent
            },

            addFeaturedMatch(){
                this.tryingToSubmit = true;
                Teams.addfeaturedMatch(this.match_id)
                .then((response) => {
                    this.tryingToSubmit = false;
                    const res = response.data.featured_match_added ? response.data.featured_match_added : false;
                    const error = response.data.error ? response.data.error : 'Failed';
                    if(res){
                        this.refreshTableFeatureMatches();
                        Swal.fire("Added!", "Featured Match added to the list", "success");
                        this.closeModal();
                    }else{
                        Swal.fire("Fail!", error, "warning");
                    }
                })
                .catch(error => {
                    this.tryingToSubmit = false;
                    Swal.fire("Fail!", error, "warning");
                });
            },
            
            getMatchesByRound(round_id) {
                this.matchesPerRound =  [];

                Teams.competitionFixturesByRound(this.competition_id, round_id)
                .then((response) => {
                    this.matchesPerRound =  response.data.data.rounds[`${round_id}`].matches;
                })
                .catch(() => {
                    this.matchesPerRound =  [];
                })
            },

            getCompetitionRounds() {
                Teams.competitionFixtures(this.competition_id)
                .then((response) => {
                    this.competitionRounds = response.data.data.rounds;
                });
            },

            closeModal() {
                this.showModal = false;
                this.round_id = null;
                this.match_id = null;
                this.matchesPerRound = [];
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },
        }
    };
</script>



<template>
    <b-modal
        @shown="getCompetitionRounds"
        id="add_featured_match"
        v-model="showModal"
        title="Add New Event"
        title-class="text-black font-18"
        body-class="p-3"
        hide-footer
        centered
    >
    <form @submit.prevent="addFeaturedMatch">
        <div class="row">
          <div class="col-12">

            <div class="mb-3">
              <label class="control-label form-label">Choose Round</label>
              <select v-model="round_id" class="form-control form-select" name="round_id" type="select" @change="getMatchesByRound(round_id)">
                <b-form-select-option :value="null">Please select an round</b-form-select-option>
                <option v-for="round in  Object.keys(competitionRounds)" :key="round" :value="round">
                    {{round }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label class="control-label form-label">Choose Match</label>
              <select v-model="match_id" class="form-control form-select" name="match_id" type="select"  :disabled="matchesPerRound.length==0">
                <b-form-select-option :value="null">Select Featured Match</b-form-select-option>
                <option v-for="match  in matchesPerRound" :key="match.match_id" :value="match.match_id">
                    {{match.home_team_name}} -  {{match.away_team_name}} ({{match.date_formatted}})
                </option>
              </select>
            </div>

          </div>
        </div>

        <div class="text-end pt-5 mt-3">
            <b-button variant="light" @click="closeModal" >Close</b-button>
            <b-button type="submit" variant="primary" class="ms-3" @click="addFeaturedMatch" :disabled="round_id==null || match_id==null || tryingToSubmit">
                Submit
            </b-button>
        </div>
      </form>
    </b-modal>
 
</template>